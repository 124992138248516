import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Container, 
  TextField, 
  IconButton,
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  InputAdornment,
  Box,
  Switch,
  FormControlLabel,
  CircularProgress
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import MovieIcon from '@mui/icons-material/Movie';

// Replace 'YOUR_OMDB_API_KEY' with your actual OMDB API key
const OMDB_API_KEY = 'a6ebbd1';

function App() {
  const [searchQuery, setSearchQuery] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [movieTitle, setMovieTitle] = useState('');
  const [darkMode, setDarkMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#1DA1F2' : '#1DA1F2',
      },
      background: {
        default: darkMode ? '#15202B' : '#E6ECF0',
        paper: darkMode ? '#192734' : '#ffffff',
      },
    },
    typography: {
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? '#192734' : '#ffffff',
            color: darkMode ? '#ffffff' : '#1DA1F2',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              borderRadius: '9999px',
              '& fieldset': {
                borderColor: darkMode ? '#38444D' : '#E6ECF0',
              },
              '&:hover fieldset': {
                borderColor: '#1DA1F2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#1DA1F2',
              },
            },
          },
        },
      },
    },
  });

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://www.omdbapi.com/?apikey=${OMDB_API_KEY}&t=${encodeURIComponent(searchQuery)}&type=movie`);
      const data = await response.json();
      if (data.Response === "True" && data.imdbID) {
        setVideoUrl(`https://vidsrc.xyz/embed/movie/${data.imdbID}`);
        setMovieTitle(data.Title);
      } else {
        alert("Movie not found!");
      }
    } catch (error) {
      console.error("Error searching for movie:", error);
      alert("An error occurred while searching for the movie.");
    }
    setLoading(false);
  };

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <IconButton edge="start" color="primary" aria-label="menu" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
            <MovieIcon color="primary" sx={{ fontSize: 30, mr: 1 }} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              VidStream
            </Typography>
            <FormControlLabel
              control={<Switch checked={darkMode} onChange={handleDarkModeToggle} />}
              label="Dark Mode"
            />
          </Toolbar>
        </AppBar>
        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search for movies"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch} color="primary" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {videoUrl && (
                <Card elevation={2} sx={{ borderRadius: 2, overflow: 'hidden', bgcolor: 'background.paper' }}>
                  <CardMedia
                    component="iframe"
                    height="450"
                    src={videoUrl}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {movieTitle}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
